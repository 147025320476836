
import Vue from "vue";

import { Evaluation, Repair } from "@/interfaces";

import { mdiSend, mdiStar, mdiStarOutline } from "@mdi/js";

import MyCard from "@/components/repair/MyCard.vue";

import http from "@/http";

export interface DataType {
  repair?: Repair;
}

export default Vue.extend({
  components: {
    MyCard
  },
  data(): DataType {
    return {
      repair: undefined
    };
  },
  computed: {
    breadcrumbs() {
      let text = "";
      if (this.repair) {
        text = `# ${this.repair.id} ${this.repair.title}`;
      }
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "修理",
          disabled: false,
          to: "/repairs"
        },
        {
          text,
          disabled: true
        }
      ];
    },
    disabled(): boolean {
      if (this.repair) {
        return this.repair.state > 0;
      }

      return true;
    },
    evaluations(): Evaluation[] {
      if (this.repair) {
        return this.repair.evaluations;
      }

      return [];
    },
    icon() {
      return {
        mdiSend,
        mdiStarOutline,
        mdiStar
      };
    }
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `/repairs/${id}`;

    const { data } = await http.get<Repair>(url);

    this.repair = data;
  },
  methods: {
    async click() {
      const { id } = this.$route.params;

      const url = `/repairs/${id}`;

      const { data } = await http.put<Repair>(url, []);

      this.repair = data;
    },
    async remove() {
      const { id } = this.$route.params;

      const url = `/repairs/${id}`;

      await http.delete(url);

      this.$router.push("/repairs/");
    }
  }
});
